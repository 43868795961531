@font-face {
font-family: 'interDisplay';
src: url(/_next/static/media/69bd2be373d79641-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'interDisplay';
src: url(/_next/static/media/1e47cc70c48d9dae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'interDisplay';
src: url(/_next/static/media/242d04bef81519ae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: 'interDisplay';
src: url(/_next/static/media/d938fffb5d040305-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'interDisplay Fallback';src: local("Arial");ascent-override: 98.56%;descent-override: 24.54%;line-gap-override: 0.00%;size-adjust: 98.29%
}.__className_c5409b {font-family: 'interDisplay', 'interDisplay Fallback'
}.__variable_c5409b {--font-inter-display: 'interDisplay', 'interDisplay Fallback'
}

